import { NUMERIC_FIELD_VALIDATION_MESSAGE, MANDATORY_FIELD } from "@/constants";
import * as yup from "yup";

export const nvcpClearingAllocationSchema = yup.object({
  id: yup.string().required(MANDATORY_FIELD.DEFAULT),
  referenceNo: yup.string().required(MANDATORY_FIELD.DEFAULT),
  clearingAllocation: yup.number().moreThan(0).required(NUMERIC_FIELD_VALIDATION_MESSAGE.MUST_BE_POSITIVE),
  noClearingAfterDate: yup.date().optional(),
  expiryDate: yup.date().optional(),
  restrictedClearing: yup.string().optional(),
  restrictedClearingId: yup.string().optional(),
  restrictedClearingAllocation: yup
    .number()
    .nullable()
    .test(MANDATORY_FIELD.DEFAULT, (value, { parent: { restrictedClearingId }, createError }) => {
      if (!restrictedClearingId) {
        return true;
      }
      if (!value || value <= 0) {
        return createError({
          path: "restrictedClearingAllocation",
          message: NUMERIC_FIELD_VALIDATION_MESSAGE.MUST_BE_POSITIVE
        });
      }
      return true;
    }),
  termsAndConditions: yup.string().optional()
});
