import { PropsWithChildren } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogContentText,
  DialogTitle,
  Grow
} from "@mui/material";

import { toKebabCase } from "@/utils";

interface DialogProps extends MuiDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  actions: DialogAction[];
  content?: string;
}

export interface DialogAction {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  isFormSubmit?: boolean;
  isLoading?: boolean;
}

export function Dialog({ open, onClose, title, content, actions, children, ...rest }: PropsWithChildren<DialogProps>) {
  return (
    <MuiDialog
      open={open}
      TransitionComponent={Grow}
      onClose={onClose}
      data-testid={toKebabCase(title) + "-dialog"}
      fullWidth
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ p: 1, px: 3 }}>
        {children ? children : <DialogContentText>{content}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <LoadingButton
            key={index}
            disabled={action.disabled}
            onClick={action.onClick}
            type={action.isFormSubmit ? "submit" : "button"}
            color="primary"
            data-testid={toKebabCase(action.label) + "-modal-button"}
            loading={action.isLoading}
          >
            {action.label}
          </LoadingButton>
        ))}
      </DialogActions>
    </MuiDialog>
  );
}
