import { createRef, useEffect, useMemo } from "react";
import { Card } from "@mui/material";
import { RichTextEditor, RichTextEditorRef } from "../rich-text-editor";

import { diffArrays, diffWords } from "diff";
import { DiffDOM, stringToObj } from "diff-dom";
import diff_match_patch from "diff-match-patch";
import { HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";

function tokenizeByTagsThenWords(str: string, splitWords: boolean = true): string[] {
  // Tokenise by tags, remove empty matches then non blank spaces.
  const split = str.split(/(<\/?[^>]+>)/).filter((x) => x !== "" && x !== "&nbsp;");

  if (!splitWords) {
    return split;
  }

  // Further tokenise words by spaces.
  return split.reduce((acc: string[], curr: string) => {
    if (!curr.startsWith("<")) {
      const words = curr.split(/\b/);
      acc.push(...words);
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
function customDiff(oldHtml: string, newHtml: string): string {
  // Helper function to split HTML content into meaningful tokens (words and tags)
  function tokenize(html: string): string[] {
    const tokens: string[] = [];
    const regex = /(<[^>]+>|[^<>\s]+|\s+)/g;
    let match: RegExpExecArray | null;

    while ((match = regex.exec(html)) !== null) {
      tokens.push(match[0]);
    }

    return tokens;
  }

  const oldTokens = tokenize(oldHtml);
  const newTokens = tokenize(newHtml);

  let oldIndex = 0;
  let newIndex = 0;
  let result = "";

  while (oldIndex < oldTokens.length || newIndex < newTokens.length) {
    if (oldIndex < oldTokens.length && newIndex < newTokens.length && oldTokens[oldIndex] === newTokens[newIndex]) {
      result += oldTokens[oldIndex];
      oldIndex++;
      newIndex++;
    } else {
      let oldChunk = "";
      let newChunk = "";

      while (
        oldIndex < oldTokens.length &&
        (newIndex >= newTokens.length || oldTokens[oldIndex] !== newTokens[newIndex])
      ) {
        oldChunk += oldTokens[oldIndex];
        oldIndex++;
      }

      while (
        newIndex < newTokens.length &&
        (oldIndex >= oldTokens.length || oldTokens[oldIndex] !== newTokens[newIndex])
      ) {
        newChunk += newTokens[newIndex];
        newIndex++;
      }

      if (oldChunk) {
        result += `<del>${oldChunk}</del>`;
      }

      if (newChunk) {
        result += `<ins>${newChunk}</ins>`;
      }
    }
  }

  return result;
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
function diffByWords(oldHtml: string, newHtml: string): string {
  const diff = diffWords(oldHtml, newHtml);
  let result = "";

  diff.forEach((part) => {
    if (part.added) {
      result += `<ins>${part.value}</ins>`;
    } else if (part.removed) {
      result += `<del>${part.value}</del>`;
    } else {
      result += part.value;
    }
  });

  return result;
}

function diffByArrays(oldHtml: string, newHtml: string, splitWords: boolean) {
  const valueOneHTML = tokenizeByTagsThenWords(oldHtml, splitWords);
  const valueTwoHTML = tokenizeByTagsThenWords(newHtml, splitWords);

  const diffs = diffArrays(valueOneHTML, valueTwoHTML);

  let diffHTML = "";

  diffs.forEach((diff) => {
    const value = diff.value.join("");

    if (diff.added) {
      diffHTML += `<mark style="background-color: lightgreen;">${value}</mark>`;
    } else if (diff.removed) {
      if (/^(<\/?[a-z]+>)$/.test(value)) {
        return;
      }
      diffHTML += `<mark style="background-color: pink;"><del>${value}<del></mark>`;
    } else {
      diffHTML += `${value}`;
    }
  });

  return diffHTML;
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
function domDiff(oldHtml: string, newHtml: string): string {
  const dd = new DiffDOM({
    textDiff: (node, currentValue, expectedValue, newValue) => {
      if (currentValue === expectedValue) {
        node.data = newValue;
      } else {
        return false;
      }
      return true;
    }
  });

  const oldParsedDom = new DOMParser().parseFromString(oldHtml, "text/html").body;
  const oldDom = stringToObj(oldHtml);
  const newDom = stringToObj(newHtml);

  const diff = dd.diff(oldDom, newDom);

  diff.forEach((change) => {
    if (change.action === "replaceElement") {
      const delElement = document.createElement("del");
      delElement.innerHTML = change.oldValue.outerHTML;
      change.oldValue.parentNode!.replaceChild(delElement, change.oldValue);
      const insElement = document.createElement("ins");
      insElement.innerHTML = change.newValue.outerHTML;
      delElement.parentNode!.insertBefore(insElement, delElement.nextSibling);
    } else if (change.action === "removeElement") {
      const delElement = document.createElement("del");
      delElement.innerHTML = change.oldValue.outerHTML;
      change.oldValue.parentNode!.replaceChild(delElement, change.oldValue);
    } else if (change.action === "addElement") {
      const insElement = document.createElement("ins");
      insElement.innerHTML = change.newValue.outerHTML;
      const parent = oldDom.querySelector(change.newParentSelector);
      parent!.appendChild(insElement);
    } else if (change.action === "modifyTextElement") {
      const delElement = document.createElement("del");
      delElement.textContent = change.oldValue;
      change.element.textContent = "";
      change.element.appendChild(delElement);

      const insElement = document.createElement("ins");
      insElement.textContent = change.newValue;
      delElement.parentNode!.insertBefore(insElement, delElement.nextSibling);
    }
  });

  dd.apply(oldParsedDom, diff);

  // Serialize the modified old DOM back to a string
  return oldParsedDom.innerHTML;
}

// eslint-disable-next-line  @typescript-eslint/no-unused-vars
function diffMatchPatch(oldHtml: string, newHtml: string): string {
  const dmp = new diff_match_patch();
  const diffs = dmp.diff_main(oldHtml, newHtml);
  dmp.diff_cleanupSemantic(diffs);

  let diffedHTML = "";

  diffs.forEach((diff) => {
    const [action, content] = diff;

    if (action === 0) {
      diffedHTML += `${content}`;
    } else if (action === -1) {
      diffedHTML += `<del>${content}</del>`;
    } else if (action === 1) {
      diffedHTML += `<ins>${content}</ins>`;
    }
  });

  return diffedHTML;
}

interface RichTextDiffViewerProps {
  originalValue: string;
  currentValue: string;
}

export function RichTextDiffEditor({ originalValue, currentValue }: RichTextDiffViewerProps) {
  const diffContentRef = createRef<RichTextEditorRef>();

  const diffContent = useMemo(() => {
    return diffByArrays(originalValue, currentValue, true);
  }, [originalValue, currentValue]);

  useEffect(() => {
    diffContentRef.current?.reset(diffContent);
  }, [diffContent, diffContentRef]);

  return (
    <>
      <Card variant="outlined" className={HIGHLIGHT_FIELD_CLASS_NAMES.BORDER} sx={{ borderColor: "primary" }}>
        <RichTextEditor ref={diffContentRef} defaultValue={diffContent} readOnly={true} hideMenuBar />
      </Card>
    </>
  );
}
