import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

import { ARFormSection, IOption } from "@/interfaces";
import { withARAwareReadOnly } from "@/components/hocs";
import { AppFormSelectProps } from "./AppFormProps";
import { HIGHLIGHT_FIELD_CLASS_NAMES } from "@/constants";

export const AppFormSelect = ({
  name,
  control,
  label,
  options,
  error,
  isOptional,
  onChangeHandler,
  resetError,
  onOpen,
  "data-testid": dataTestId,
  size,
  margin,
  disabled,
  readOnly,
  initialValue,
  formHelperErrorClass,
  isHighlight,
  sx,
  hideHelperText
}: AppFormSelectProps) => {
  const generateOptions = () => {
    return options.map((option: IOption, index: number) => {
      return (
        <MenuItem key={index} value={option.id} sx={option.style}>
          {option.value}
        </MenuItem>
      );
    });
  };

  const selectLabelText = isOptional ? `${label}` : `${label}*`;

  return (
    <FormControl error={!!error} fullWidth size={size} sx={sx}>
      <InputLabel sx={{ color: "rgba(0,0,0,0.4)" }} id="select-label">
        {selectLabelText}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={initialValue}
        render={({ field: { onChange, ref, value } }) => (
          <Select
            onChange={(event) => {
              onChange(event);
              onChangeHandler?.(event, name);
              resetError?.();
            }}
            data-testid={dataTestId}
            displayEmpty
            labelId="select-label"
            id={`select${label ? "-" + label : ""}`}
            label={selectLabelText}
            sx={{
              textAlign: "left",
              minWidth: "180px",
              "&.Mui-error.Mui-disabled": {
                fieldset: {
                  borderColor: "error.main"
                }
              }
            }}
            className={isHighlight ? HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND : ""}
            value={value ?? ""}
            error={!!error}
            onOpen={onOpen}
            ref={ref}
            size={size}
            margin={margin}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "15rem"
                }
              }
            }}
            disabled={disabled}
            readOnly={readOnly}
          >
            {generateOptions()}
          </Select>
        )}
      />
      {!hideHelperText ? (
        <FormHelperText error sx={{ marginBottom: 1 }} className={error ? formHelperErrorClass : undefined}>
          {/*Empty string is to avoid the height being manipulate when appending the error message*/}
          {error?.message ? error.message : " "}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export const ARAwareReadOnlyAppFormSelect = withARAwareReadOnly(ARFormSection.BasicForm, AppFormSelect);
