import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BUTTON_LABELS } from "@/constants";
import { Button, Grid, SelectChangeEvent } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Dialog } from "@/components/dialogs";
import { ClearingAllocation, DisciplineAuthorisationCommentValue, IOption } from "@/interfaces";
import { useForm } from "react-hook-form";
import { AppFormDateInput, AppFormInput, AppFormSelect } from "@/components/fields";
import { useInstrumentIdList, useRestrictedClearingList } from "@/hooks";
import { nvcpClearingAllocationSchema } from "@/validations";
import { useAuthorization } from "@/context";
import dayjs from "dayjs";
import { isEqual } from "lodash";

interface NVCPClearingAllocationTableToolbarProps {
  setModalDialogAction: Dispatch<SetStateAction<"Add" | "Edit" | undefined>>;
  modalDialogAction: string;
  disabled: boolean;
  rowIsSelected: boolean;
  selectedRow: ClearingAllocation;
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  clearingAllocations: ClearingAllocation[];
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
}

export function NVCPClearingAllocationTableToolbar({
  setModalDialogAction,
  modalDialogAction,
  disabled,
  rowIsSelected,
  selectedRow,
  disciplineAuthCommentValue,
  clearingAllocations,
  onUpdate
}: NVCPClearingAllocationTableToolbarProps) {
  const modalDialogIsOpen = modalDialogAction !== undefined;
  const [selectedReferenceNo, setSelectedReferenceNo] = useState<string>();
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState<boolean>(false);
  const { data: instrumentIdList } = useInstrumentIdList();
  const { data: restrictedClearingList, refetch: refetchRestrictedClearingList } =
    useRestrictedClearingList(selectedReferenceNo);

  const { userId, username } = useAuthorization();

  const clearSelectionOption: IOption = {
    id: "clearSelection",
    value: "clearSelection",
    style: { color: "transparent" }
  };

  const defaultValues: ClearingAllocation = {
    id: crypto.randomUUID(),
    referenceNo: "",
    clearingAllocation: null,
    noClearingAfterDate: undefined,
    expiryDate: undefined,
    restrictedClearingId: "",
    restrictedClearing: "",
    restrictedClearingAllocation: null,
    termsAndConditions: "",
    createdBy: userId,
    createdByName: username
  };

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
    reset
  } = useForm<ClearingAllocation>({ defaultValues });

  const handleDialogOpen = (action: "Add" | "Edit") => {
    if (action === "Edit") {
      reset(selectedRow);
      setSelectedReferenceNo(selectedRow.referenceNo);
    } else {
      reset(defaultValues);
    }
    setModalDialogAction(action);
  };

  const handleDialogClose = () => {
    setModalDialogAction(undefined);
    setSelectedReferenceNo(undefined);
  };

  const handleAdd = () => {
    const formValues = getValues();
    formValues.created = dayjs().utc().toDate();
    const newClearingAllocation = [formValues, ...clearingAllocations];
    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(newClearingAllocation)
    });
    handleDialogClose();
  };

  const handleEdit = () => {
    const formValues = getValues();
    formValues.lastModified = dayjs().utc().toDate();
    formValues.lastModifiedBy = userId;
    formValues.lastModifiedByName = username;
    const updatedClearingAllocation = clearingAllocations.map((row) => (row.id === formValues.id ? formValues : row));

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(updatedClearingAllocation)
    });
    handleDialogClose();
  };

  const handleDelete = () => {
    const filteredClearingAllocations = clearingAllocations.filter((row) => row.id !== selectedRow.id);

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(filteredClearingAllocations)
    });
  };

  useEffect(() => {
    refetchRestrictedClearingList();
  }, [refetchRestrictedClearingList, selectedReferenceNo]);

  useEffect(() => {
    if (!modalDialogIsOpen) return;
    const formValues = watch();
    const formIsValid = nvcpClearingAllocationSchema.isValidSync(formValues);
    const isDirty = !isEqual(formValues, selectedRow);
    const disableApplyButton = modalDialogAction === "Add" ? !formIsValid : !isDirty || !formIsValid;
    setIsApplyButtonDisabled(disableApplyButton);
  }, [modalDialogAction, selectedRow, modalDialogIsOpen, watch()]);

  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="text"
        color="secondary"
        data-testid="nvcp-table-add-button"
        disabled={disabled}
        onClick={() => handleDialogOpen("Add")}
      >
        Add
      </Button>
      <Button
        variant="text"
        color="secondary"
        data-testid="nvcp-table-edit-button"
        disabled={!rowIsSelected || disabled}
        onClick={() => handleDialogOpen("Edit")}
      >
        Edit
      </Button>
      <Button
        variant="text"
        color="secondary"
        data-testid="nvcp-table-delete-button"
        disabled={!rowIsSelected || disabled}
        onClick={handleDelete}
      >
        Delete
      </Button>

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        data-testid={"clearing-allocation-modal"}
        open={modalDialogIsOpen}
        onClose={handleDialogClose}
        title={modalDialogAction === "Add" ? "Add Clearing Mechanism" : "Update Clearing Mechanism"}
        actions={[
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: handleDialogClose
          },
          {
            label: BUTTON_LABELS.APPLY,
            disabled: isApplyButtonDisabled,
            onClick: modalDialogAction === "Add" ? handleAdd : handleEdit
          }
        ]}
      >
        <Grid container spacing={{ xs: 1, sm: 2 }} columns={12} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <AppFormSelect
              name={"referenceNo"}
              control={control}
              error={errors.id}
              data-testid="select-clearing-instrument-referenceNo"
              showError={false}
              label={"Clearing instrument"}
              options={instrumentIdList}
              isOptional={true}
              onChangeHandler={(event) => {
                const value = (event as SelectChangeEvent).target.value;
                setSelectedReferenceNo(value);
                setValue("restrictedClearingAllocation", defaultValues.restrictedClearingAllocation);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormInput
              control={control}
              label={"Clearing allocation (ha)"}
              name={"clearingAllocation"}
              error={errors.clearingAllocation}
              data-testid="input-clearing-allocation"
              showError={false}
              type="number"
              disabled={selectedReferenceNo === undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormDateInput
              name={"noClearingAfterDate"}
              control={control}
              error={errors.noClearingAfterDate}
              data-testid="date-no-clearing-after"
              showError={false}
              label={"No clearing after"}
              disabled={selectedReferenceNo === undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormDateInput
              name={"expiryDate"}
              control={control}
              error={errors.expiryDate}
              data-testid="date-expiry-date"
              showError={false}
              label={"Expiry"}
              disabled={selectedReferenceNo === undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormSelect
              name={"restrictedClearingId"}
              control={control}
              error={errors.restrictedClearingId}
              data-testid="select-restricted-clearing-id"
              label={"Restricted clearing"}
              options={[clearSelectionOption, ...restrictedClearingList]}
              isOptional={true}
              showError={false}
              onChangeHandler={(event) => {
                setValue("restrictedClearingAllocation", defaultValues.restrictedClearingAllocation);
                const value = (event as SelectChangeEvent).target.value;
                if (value === clearSelectionOption.id) {
                  setValue("restrictedClearingId", defaultValues.restrictedClearingId);
                  setValue("restrictedClearing", defaultValues.restrictedClearing);
                  return;
                }

                setValue("restrictedClearing", restrictedClearingList.filter((row) => row.id === value)[0].value);
              }}
              disabled={selectedReferenceNo === undefined}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormInput
              control={control}
              label={"Restricted clearing allocation (ha)"}
              name={"restrictedClearingAllocation"}
              error={errors.restrictedClearingAllocation}
              data-testid="input-restricted-clearing-allocation"
              showError={false}
              type="number"
              disabled={selectedReferenceNo === undefined || getValues().restrictedClearingId === ""}
            />
          </Grid>
          <Grid item xs={12}>
            <AppFormInput
              control={control}
              label={"Terms and Conditions"}
              name={"termsAndConditions"}
              error={errors.termsAndConditions}
              data-testid="input-terms-and-conditions"
              showError={false}
              multiline={true}
              rows={5}
              disabled={selectedReferenceNo === undefined}
            />
          </Grid>
        </Grid>
      </Dialog>
    </GridToolbarContainer>
  );
}
