import { MouseEvent, createRef, useEffect, useState } from "react";
import { Box, Button, Card, IconButton, Stack } from "@mui/material";
import { ModeEdit } from "@mui/icons-material";

import { DisciplineAuthorisationCommentValue } from "@/interfaces";
import { AUTH_COMMENT_BUTTON_LABELS } from "@/constants";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor";
import { ConfigurableTextContent } from "./ConfigurableTextContent";
import { ConfigurableTextField } from "./ConfigurableTextField";

interface ConfigurableEditableTextContentProps {
  key: string;
  configuration: DisciplineAuthorisationCommentValue;
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isEditable: boolean;
  onActionHandler: (isEdit: boolean) => void;
  disabled: boolean;
}

export function ConfigurableEditableTextContent({
  configuration,
  onUpdate,
  isEditable,
  onActionHandler,
  disabled
}: ConfigurableEditableTextContentProps) {
  const [isRichTextOnEdit, setIsRichTextOnEdit] = useState<boolean>(false);

  const editorRef = createRef<RichTextEditorRef>();
  const editorValue = configuration.value ? configuration.authorisationCommentField.defaultValue : configuration.value;
  const [updateValue, setUpdateValue] = useState<string>(editorValue);

  useEffect(() => {
    if (!isEditable) {
      editorRef.current?.clearContent();
      setIsRichTextOnEdit(false);
    }
  }, [isEditable]);

  const handlerButtonRestore = (event: MouseEvent) => {
    event.preventDefault();
    editorRef.current?.reset(configuration.authorisationCommentField.defaultValue);
    setUpdateValue(configuration.authorisationCommentField.defaultValue);
  };

  const handlerButtonCancel = (event: MouseEvent) => {
    event.preventDefault();
    editorRef.current?.clearContent();
    setIsRichTextOnEdit(false);
    onActionHandler(false);
  };

  const handlerButtonSave = (event: MouseEvent) => {
    event.preventDefault();
    let constValue = updateValue;
    if (configuration.authorisationCommentField.defaultValue === updateValue) {
      constValue = "";
    }
    onUpdate({
      ...configuration,
      value: constValue
    });

    onActionHandler(false);
    setIsRichTextOnEdit(false);
  };
  return (
    <Stack direction={"row"}>
      <Stack sx={{ width: "95%" }}>
        {isRichTextOnEdit ? (
          <>
            <Box component="div" display="flex" flexGrow={1}>
              <Card variant="outlined" sx={{ borderColor: "primary", width: "100%" }}>
                <RichTextEditor
                  key={`auth-comment-config-${configuration.authorisationCommentField.name}-${configuration.id}`}
                  placeholder={configuration.authorisationCommentField.label}
                  data-testid={`auth-comment-config-${configuration.authorisationCommentField.name}-${configuration.id}`}
                  ref={editorRef}
                  autoFocus={true}
                  defaultValue={editorValue}
                  onChange={() => setUpdateValue(editorRef.current?.getContent(true) ?? editorValue)}
                />
              </Card>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: "1rem" }}>
              <Button
                key={`button-restore-${configuration.authorisationCommentField.name}-${configuration.id}`}
                data-testid={`button-restore-${configuration.authorisationCommentField.name}-${configuration.id}`}
                variant="outlined"
                disabled={configuration.authorisationCommentField.defaultValue === updateValue}
                onClick={handlerButtonRestore}
              >
                {AUTH_COMMENT_BUTTON_LABELS.RESTORE}
              </Button>
              <Stack direction="row" spacing={2}>
                <Button
                  key={`button-cancel-${configuration.authorisationCommentField.name}-${configuration.id}`}
                  data-testid={`button-cancel-${configuration.authorisationCommentField.name}-${configuration.id}`}
                  variant="outlined"
                  onClick={handlerButtonCancel}
                >
                  {AUTH_COMMENT_BUTTON_LABELS.CANCEL}
                </Button>
                <Button
                  key={`button-save-${configuration.authorisationCommentField.name}-${configuration.id}`}
                  data-testid={`button-save-${configuration.authorisationCommentField.name}-${configuration.id}`}
                  variant="outlined"
                  disabled={editorValue === updateValue}
                  onClick={handlerButtonSave}
                >
                  {AUTH_COMMENT_BUTTON_LABELS.SAVE}
                </Button>
              </Stack>
            </Stack>
          </>
        ) : (
          <>
            {configuration.authorisationCommentField.defaultValue !== "" || configuration.value !== "" ? (
              <ConfigurableTextContent
                key={`auth-comment-config-text-${configuration.authorisationCommentField.name}-${configuration.id}`}
                data-testid={`auth-comment-config-text-${configuration.authorisationCommentField.name}-${configuration.id}`}
                content={
                  configuration.value === ""
                    ? configuration.authorisationCommentField.defaultValue
                    : configuration.value
                }
              />
            ) : (
              <ConfigurableTextField
                key={`auth-comment-config-textfield-${configuration.authorisationCommentField.name}-${configuration.id}`}
                label={configuration.authorisationCommentField.label}
                type={"input"}
                configuration={configuration}
                disabled={true}
                onUpdate={() => {}}
              />
            )}
          </>
        )}
      </Stack>
      <Stack sx={{ width: "5%", pl: "1rem" }}>
        <IconButton
          key={`icon-button-edit-${configuration.authorisationCommentField.name}-${configuration.id}`}
          data-testid={`icon-button-edit-${configuration.authorisationCommentField.name}-${configuration.id}`}
          color="default"
          disabled={disabled || isEditable}
          onClick={() => {
            onActionHandler(true);
            setIsRichTextOnEdit(true);
          }}
          size="small"
          sx={{ p: 0 }}
        >
          <ModeEdit fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
